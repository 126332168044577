<template>
  <div class="page flex-col justify-between">
    <div class="box_1 flex-col" id="home">
     
      <div class="group_1 flex-row">
        <span class="text_1">Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R.</span>
        <div class="text-wrapper_1 flex-col"><span class="text_2" @click="jumpage('#home')">Inicio</span></div>
        <span class="text_3" @click="jumpage('#about')">Acerca </span>
        <span class="text_4" @click="jumpage('#services')">Ventajas</span>
        <span class="text_5" @click="jumpage('#contact')">Contáctenos</span>
        <span class="text_5_1" @click="jumpage('#policy')">Privacidad</span>
      </div>
      <div class="text-group_13 flex-col justify-between" >
        <span class="text_6">Construcción de crédito, abriendo un mundo financiero más amplio</span>
        <span class="text_7">
          Creamos una plataforma de servicios financieros segura, conveniente e innovadora para usted, dedicada a brindarle soluciones financieras personalizadas que lo ayuden a alcanzar sus objetivos financieros y a abrir una nueva experiencia financiera.
        </span>
      </div>
    </div>
    <div class="box_2 flex-col" id="contact">
      <div class="group_16 flex-row">
        <div class="text-group_14 flex-col justify-between">
          <span class="text_8">Digital Moneyboxmx,<br> S.A. de C.V., SOFOM, E.N.R.</span>
          <span class="text_9">
            Plataforma de tecnología financiera confiable, proporcionando soluciones financieras integrales a los clientes.
          </span>
        </div>
        <div class="text-wrapper_18 flex-col justify-between">
          <!-- <span class="text_10">Compañía&nbsp;colaboradora</span>
          <span class="text_11">
            Créditos&nbsp;Formales&nbsp;de&nbsp;México,&nbsp;S.A.P.I.&nbsp;de&nbsp;C.V.,&nbsp;SOFOM,&nbsp;E.N.R.
          </span> -->
        </div>
        <div class="group_17 flex-col">
          <span class="text_12">Contáctenos</span>
          <div class="image-text_5 flex-row justify-between">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng68439ee78536f2324175e9306a71d5a5e241e01019cab377fa670e3722af7c11.png"
            />
            <span class="text-group_3">customer@digitalmoneyboxmx.com</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfe0e39caf99fdade489c09b4ddc7ce8a176287a9fcbd3fced3da417bfd589e34.png"
            />
            <span class="text-group_4">
              Avenida Javier Barros Sierra 540, Piso 5, Santa Fe, Alcaldía Álvaro Obregón, Ciudad de México, C.P. 01210
            </span>
          </div>
        </div>
      </div>
     
      
      <div class="group_3 flex-col"></div>
      <span class="text_13">&nbsp;©&nbsp;Copyright&nbsp;by&nbsp;Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R.</span>
    </div>
    
    <div class="block_4 flex-col">
      <div class="list_3 flex-row">
        <div class="list-items_1 flex-col" v-for="(item, index) in loopData0" :key="index">
          <div class="image-wrapper_1 flex-col" :style="{ background: item.lanhuBg1 }">
            <img class="label_2" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
          </div>
          <span class="text_14" v-html="item.lanhutext0"></span>
          <span class="text_15" v-html="item.lanhutext1"></span>
        </div>
      </div>
      <div class="section_11 flex-row justify-between" id="about">
        <div class="text-group_15 flex-col justify-between">
          <span class="text_50">Acerca de nosotros</span>
          <span class="text_51">
            Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R. es una empresa financiera mexicana local comprometida en crear una plataforma de tecnología financiera confiable mediante la innovación tecnológica y servicios personalizados, ofreciendo soluciones financieras integrales a nuestros clientes. <br><br>

            La entidad financiera incluye información sobre nuestras características de productos y cómo nos relacionamos con los usuarios al brindar servicios, proveniente de Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R.<br><br>

            La información registrada en esta entidad y dicha información corresponde únicamente a la entidad financiera correspondiente y proporciona una visión general de todo el departamento relacionado con ella. Le invitamos a consultarla en el sitio web https://www.buro.gob.mx/ o en nuestro sitio web https://www.digitalmoneyboxmx.com.
          </span>
        </div>
        <div class="block_5 flex-col justify-between">
          <div class="section_8 flex-col"></div>
          <div class="section_9 flex-col"></div>
          <div class="section_10 flex-col"></div>
        </div>
      </div>
     
      <div class="box_6 flex-col" id="services">
       
        <span class="text_26">Nuestras ventajas</span>
      </div>


      <div class="Productos">
          <div class="Productostitle">
            Productos financieros personalizados
          </div>
          <div class="Productostext">
            ¡Bienvenido al mundo de nuestros productos financieros personalizados! Aquí no solo ofrecemos servicios financieros estándar, sino que también nos dedicamos a comprender sus necesidades únicas y desarrollar soluciones a medida para usted. Ya sea que esté buscando préstamos, inversiones o consejos financieros, le proporcionamos orientación y apoyo personalizados. ¡Trabajemos juntos para crear un futuro que haga realidad sus sueños financieros!
          </div>
          <div class="Productoscardmax">
            <div class="Productoscard Productoscardbg1">
            <div class="cardheader">
              <img src="./partner/logo.png" alt="">
              Digital Moneyboxmx
            </div>
            <div class="cardtipss">
              Digital Moneyboxmx es una aplicación financiera en línea desarrollada por Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R. Posee una licencia SOFOM reconocida por la agencia reguladora financiera mexicana y cumple totalmente con las regulaciones mexicanas pertinentes. Préstamo Seguro se compromete a brindar a los usuarios un soporte financiero rápido, transparente y flexible para ayudarlos a resolver fácilmente sus necesidades de financiamiento a corto plazo.
            </div>
          </div>

          <div class="Productoscard Productoscardbg2">
            <div class="cardheader">
              <img src="./partner/logo2.png" alt="">
              MAXPréstamo Plus
            </div>
            <div class="cardtipss">
              MAXPréstamo Plus es una aplicación financiera en línea creada por Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R. Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R está licenciada y certificada por el regulador financiero mexicano, y la aplicación cumple totalmente con las regulaciones locales vigentes. MAXPréstamo Plus se compromete a brindar a los usuarios un soporte financiero ágil, confiable y flexible para ayudar a resolver sus necesidades de financiamiento a corto plazo de manera eficiente y segura.
            </div>
          </div>

            
           
          </div>
         


        </div>

      
      <div class="section_12 flex-row justify-between">
        <div class="group_18 flex-col">
         
          <span class="text_17">Experiencia de servicios financieros integrales</span>
          <span class="text_18">
            Ofrecemos servicios financieros integrales para ayudar a los usuarios a alcanzar sus objetivos financieros y mejorar su calidad de vida.
          </span>
          <div class="group_19 flex-row justify-between">
            <div class="image-wrapper_2 flex-col">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng013bd9020f86e45b4ddfab0d2ba426f7f37a7d4648bfbf911e62d15a88b6066a.png"
              />
            </div>
            <div class="text-wrapper_19 flex-col justify-between">
              <span class="text_19">Personalización</span>
              <span class="text_20">
                Nos enfocamos en las necesidades individuales de los usuarios, utilizando análisis de datos profundos y algoritmos inteligentes para personalizar productos y servicios financieros que se adapten a ellos.
              </span>
            </div>
          </div>
          <div class="group_20 flex-row justify-between">
            <div class="image-wrapper_3 flex-col">
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPnge2fb09e637e2b83783bf7512a642043522f2672f97619f4faae7ce13b565d474.png"
              />
            </div>
            <div class="text-group_16 flex-col justify-between">
              <span class="text_21">Seguridad y confiabilidad</span>
              <span class="text_22">
                Nos comprometemos a construir un ecosistema financiero seguro y confiable, utilizando estrictas medidas de seguridad y tecnología de cifrado para garantizar la seguridad de la información financiera y las transacciones de los usuarios.
              </span>
            </div>
          </div>
          <div class="group_21 flex-row justify-between">
            <div class="image-wrapper_4 flex-col">
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng423b815f83a8a96d11a65d864f4401be767f6a94818e1c27931255b4cf752609.png"
              />
            </div>
            <div class="text-group_17 flex-col justify-between">
              <span class="text_23">Rapidez y comodidad</span>
              <span class="text_24">
                Nuestra plataforma es fácil de usar, con procesos claros que permiten a los usuarios completar fácilmente diversas operaciones financieras y disfrutar de servicios financieros de alta calidad.
              </span>
            </div>
          </div>
        </div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng1741575389850ebf40aaa5803de22d54f8799d411adb000e9c1064b48cac741e.png"
        />
      </div>
      <div class="box_11 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng211ce3bc4455fd4ef5c928dad7123928cdb1d2195c78774718cc0cdee97dbaa4.png"
        />
        <span class="text_47">Tecnología innovadora</span>
      </div>
      <div class="box_12 flex-col">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc2209b3eda3dc321489f31e35f9359bf666074e0cf39c8860f4c859bd4667296.png"
        />
        <span class="text_48">Personalización inteligente</span>
      </div>
      <div class="box_13 flex-col">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng91781eb705bba0ffc4c50c40cbcb4ce493586efe8a7e0a892c4dc887ab3804e4.png"
        />
        <span class="text_49">Seguridad confiable</span>
      </div>

     
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg1:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6a86efa90b49a6935387d48464f83fd8478abfa74a737fd0f1ee1012a0194c34) 100% no-repeat',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng50f4373ecd08ec2e68f98a4d0eed357e0ece33086a8e13fbaf4f4e40599d5644',
          lanhutext0: 'Servicios de préstamos digitalizados',
          lanhutext1:
            'Ofrecemos soluciones de préstamos flexibles para clientes individuales y empresariales.'
        },
        {
          lanhuBg1:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga76efb4ec8bf6602252ca51a47a0d548255921d44f58f73df92fad687465f145) 100% no-repeat',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f600d9cf638e5ab0670c938a72a77b32787a5626d9006e74002f0e597ba400e',
          lanhutext0: 'Evaluación y gestión de créditos',
          lanhutext1:
            'Brindamos servicios integrales de evaluación crediticia y ayudamos a construir y gestionar registros crediticios sólidos.'
        },
        {
          lanhuBg1:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng509fe3fdd56073e3946a3a413944691d987887d9e4f9247fb684fc0561049d40) 100% no-repeat',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6495a8bccaf72d57f556b0756d216fe0ca8eb06454218d37c9f3af2f28f0ef1b',
          lanhutext0: 'Soluciones de tecnología financiera',
          lanhutext1:
            'Proporcionamos soluciones de tecnología financiera personalizadas para instituciones financieras y clientes empresariales.'
        }
      ],
      constants: {}
    };
  },
  watch: {
    // 监听路由变化
    '$route' (to) {
      this.checkPartnerParam(to);
    }
  },
  mounted() {
    // 页面加载时也需要检查一次
    this.checkPartnerParam(this.$route);
  },

  methods: {
    checkPartnerParam(route) {
     console.log(route)
      // 检查 URL 中是否存在 partner=1
      if (route.fullPath.includes('partner=1')) {
        // 滚动页面 50vw
        this.scrollPage();
      }
    },
    scrollPage() {
      // 计算 50vw
      const scrollAmount = window.innerWidth * 1.4;
      // 滚动页面
      window.scrollTo({
        top: scrollAmount,
        behavior: 'smooth'
      });
    },
    jumpage(selector){
      if(selector=='#policy'){
        window.open('/policy')
        return
      }
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    },
    jumappstore(id){
      switch (id) {
          case 1:
          window.open('https://apps.apple.com/mx/app/id6504483299?l=es-mx')
          break;
          case 2:
          window.open('https://apps.apple.com/mx/app/id6467835260?l=es-mx')
          break;
          case 3:
          window.open('https://apps.apple.com/mx/app/id6476123908?l=es-mx')
          break;
          case 4:
          window.open('https://apps.apple.com/mx/app/id6476123908?l=es-mx')
          break;  
      
        default:
          break;
      }
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />